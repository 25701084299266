<template>
  <div>
    <transition name="fade" mode="out-in" appear>
      <div v-if="show" class="service-container">
        <div
          v-for="service in services"
          v-bind:key="service.id"
          class="item-container"
        >
          <img :src="service.icon" alt="icon" />
          <h2>{{ service.title }}</h2>
          <p>{{ service.desc }}</p>
          <button>Learn More</button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
// Service Icons
import icon1 from "../assets/mixing-mastering.svg";
import icon2 from "../assets/web-dev-design.svg";
import icon3 from "../assets/music.svg";

export default {
  name: "Services",
  data() {
    return {
      show: true,
      services: [
        {
          id: 1,
          icon: icon1,
          title: "Mixing & Mastering",
          desc:
            "I will mix and master your tracks whether it be a single, EP or even an album",
        },
        {
          id: 2,
          icon: icon2,
          title: "Web Development",
          desc:
            "I will design and build your very own proffesioinal artist websiteto host your tracks, merch et cetera.. ",
        },
        {
          id: 3,
          icon: icon3,
          title: "Music Promotion",
          desc:
            "Submit your music for a chance to be featured on the “Demigod Music” channel",
        },
      ],
    };
  },
};
</script>

<style scoped>
.service-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  position: absolute;
  top: 30%;
  right: 0;
  left: 0;
}

.item-container {
  width: 25%;
  margin: 25px;
}

img {
  width: 25%;
}

h2 {
  color: #b94242;
}

button {
  font-size: 1rem;
  padding: 10px;
  border: none;
  border-radius: none;
  background-color: #942e2e;
  color: #fff;
  text-transform: uppercase;
  cursor: pointer;
}

@media only screen and (max-width: 830px) {
  .service-container {
    flex-direction: column;
    flex-wrap: nowrap;
    top: 10%;
  }

  .item-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 50px 0 50px 0;
  }

  p {
    width: 50%;
  }
}
</style>
